.nodetype-memo {
    border-radius: 3px;
    color: #222;
    text-align: center;
    border-width: 1px;
    border-style: solid;
    border-color: #e0e0e0;
    background-color: white;
}

.nodetype-memo .dark-theme {
    border-radius: 3px;
    color: #222;
    text-align: center;
    border-width: 1px;
    border-style: solid;
    border-color: #fff;
    background-color: white;
}