.nodetype-group .react-flow__resize-control {
    position: absolute;
}

.nodetype-group .react-flow__resize-control.left,
.nodetype-group .react-flow__resize-control.right {
    cursor: ew-resize;
}

.nodetype-group .react-flow__resize-control.top,
.nodetype-group .react-flow__resize-control.bottom {
    cursor: ns-resize;
}

.nodetype-group .react-flow__resize-control.top.left,
.nodetype-group .react-flow__resize-control.bottom.right {
    cursor: nwse-resize;
}

.nodetype-group .react-flow__resize-control.bottom.left,
.nodetype-group .react-flow__resize-control.top.right {
    cursor: nesw-resize;
}

/* handle styles */

.nodetype-group .react-flow__resize-control.handle {
    width: 10px;
    height: 10px;
    border: 3px solid #fff;
    border-radius: 1px;
    background-color: #ff1177;
    /*background-color: #3367d9;*/
    transform: translate(-50%, -50%);
}

.nodetype-group .react-flow__resize-control.handle.left {
    left: 0;
    top: 50%;
}

.nodetype-group .react-flow__resize-control.handle.right {
    left: 100%;
    top: 50%;
}

.nodetype-group .react-flow__resize-control.handle.top {
    left: 50%;
    top: 0;
}

.nodetype-group .react-flow__resize-control.handle.bottom {
    left: 50%;
    top: 100%;
}

.nodetype-group .react-flow__resize-control.handle.top.left {
    left: 0;
}

.nodetype-group .react-flow__resize-control.handle.bottom.left {
    left: 0;
}

.nodetype-group .react-flow__resize-control.handle.top.right {
    left: 100%;
}

.nodetype-group .react-flow__resize-control.handle.bottom.right {
    left: 100%;
}

/* line styles */

.nodetype-group .react-flow__resize-control.line {
    border-color: #ff1177;
    /*border-color: #3367d9;*/
    border-width: 0;
    border-style: solid;
}

.nodetype-group .react-flow__resize-control.line.left,
.nodetype-group .react-flow__resize-control.line.right {
    width: 1px;
    transform: translate(-50%, 0);
    top: 0;
    height: 100%;
}

.nodetype-group .react-flow__resize-control.line.left {
    left: 0;
    border-left-width: 5px;
}

.nodetype-group .react-flow__resize-control.line.right {
    left: 100%;
    border-right-width: 5px;
}

.nodetype-group .react-flow__resize-control.line.top,
.nodetype-group .react-flow__resize-control.line.bottom {
    height: 1px;
    transform: translate(0, -50%);
    left: 0;
    width: 100%;
}

.nodetype-group .react-flow__resize-control.line.top {
    top: 0;
    border-top-width: 5px;
}

.nodetype-group .react-flow__resize-control.line.bottom {
    border-bottom-width: 5px;
    top: 100%;
}
