code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body>#root>div {
  overflow-y: scroll;
}

body>#root>div {
  height: 100vh;
}

.App::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.App {
  /* IE and Edge */
  /* Firefox */
  /* outline: 1px solid; */
}

.invisible-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.invisible-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  /* outline: 1px solid; */
}

.MuiBox-root::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.MuiBox-root {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  /* outline: 1px solid; */
}
