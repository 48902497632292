.text-updater-node {
    border: 1px solid #eee;
    border-radius: 5px;
    background: white;
  }
  
.text-updater-node label {
  display: block;
  color: #777;
  font-size: 12px;
}
  